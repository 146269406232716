import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import ProjectPlanningTable from './ProjectPlanningTable';
import ProjectOutcomesTable from './ProjectOutcomesTable';
import PhosCreditCalc from './PhosCreditCalc';
import { useProjects } from '../../services/Projects_Service';
import { useCriteria } from '../../services/Criteria_Service';
import { useRegions } from '../../services/Region_Service';
import { useAppState } from '../../services/App_State_Service';
import {BsFillTriangleFill} from 'react-icons/bs';
import {FcExpand} from 'react-icons/fc';
import {BiOutline} from 'react-icons/bi';
import {BiLinkExternal} from 'react-icons/bi';
import {BsSearch} from 'react-icons/bs'
import './TableWrapper.scss';
import LocalPopupItem from '../Map/LocalPopupItem';
import config from '../../config';
import Query from "@arcgis/core/rest/support/Query";
import {LayersIcon} from '../SVGs/Icon';
import ProjectScreening from './ProjectScreening';
import WaterQualityBenefit from '../WaterQualityBenefit/WaterQualityBenefit';
import CostEffectiveness from '../CostEffectiveness/CostEffectiveness';
import Resiliency from '../Resiliency/Resiliency';

export default function TableWrapper(props) {
    // const [showTable, setShowTable] = useState(false);
    // const [tableSelected, setTableSelected] = useState("");
    const [pastSelectedRows,setPastSelectedRows] = useState([]);
    //maybe move these to the projectScreening component
    const [riverCorrShown, setRiverCorrShown] = useState([]);
    const [streamSegmentShown, setStreamSegmentShown] = useState([]);
    //checkedStreamFFI_Ids is used to autocheck the streams
    const [checkedStreamFFI_Ids, setCheckedStreamFFI_Ids] = useState([]);
    const [checkedRiverFFI_Ids, setCheckedRiverFFI_Ids] = useState([]);
    const [expandAll, setExpandAll] = useState(true);
    const [highlight_projScreen, setHighlight_projScreen] = useState(false);
    const [highlight_calcInput, setHighlight_calcInput] = useState(false);
    const [highlight_wqBenefit, setHighlight_wqBenefit] = useState(false);
    const [highlight_floodBenefit, setHighlight_floodBenefit] = useState(false);

    const projectsSvc = useProjects();
    const criteriaSvc = useCriteria();
    const regionSvc = useRegions();
    const appStateSvc = useAppState();


    useEffect(() => {
        // contentGeneration();
    }, [riverCorrShown, streamSegmentShown, projectsSvc.filteredProjectPlanningList]);

    function magnifyClick(a){
        if(a.featuretype == 'stream'){
            projectsSvc.setSelectedFeatureType('stream');
            return projectsSvc.setSelectedProjects(a.ffi_id);
        }else if (a.featuretype == 'rivercorridor'){
            projectsSvc.setSelectedFeatureType('rivercorridor');
            return projectsSvc.setSelectedProjects(a.ffi_id);
        }
    }

    // function testScreenshot(){
    //     /* 
    //     window.open('', document.getElementById('the_canvas_element_id').toDataURL())
    //     */
    //    let target = document.querySelector('root');
    //    console.log('target', target)
    //    html2canvas(target).then((res) => {
    //         document.body.appendChild(res);
    //    })
    // }

    function FFITableExport(){
        let arr = [];
        for(let i = 0; i < projectsSvc.filteredProjectPlanningList.length; i++){
            let newObj = {};
            console.log('i', i)
            //top
            newObj.ffi_id = projectsSvc.filteredProjectPlanningList[i].ffi_id;
            //top
            newObj.conn_scr = projectsSvc.filteredProjectPlanningList[i].conn_scr;
            //top
            newObj.feas_tot = projectsSvc.filteredProjectPlanningList[i].feas_tot;
            //top
            newObj.ir = projectsSvc.filteredProjectPlanningList[i].ir;
            if(projectsSvc.filteredProjectPlanningList[i].rc_projects.length && projectsSvc.filteredProjectPlanningList[i].rc_projects[0] !== null){
                for(let k = 0; k < projectsSvc.filteredProjectPlanningList[i].rc_projects.length; k++){
                    newObj.rc_project_name = projectsSvc.filteredProjectPlanningList[i].rc_projects[k].rc_project_name;
                    //rc_projects
                    newObj.priority = projectsSvc.filteredProjectPlanningList[i].rc_projects[k].priority;
                    //rc_projects
                    newObj.wshd_pcredit = projectsSvc.filteredProjectPlanningList[i].rc_projects[k].wshd_pcredit;
                };
            }
            if(projectsSvc.filteredProjectPlanningList[i].st_data.length && projectsSvc.filteredProjectPlanningList[i].st_data[0] == null){
                for(let k = 0; k < projectsSvc.filteredProjectPlanningList[i].st_data[k].length; k++){
                    newObj.ffi_id_stream = projectsSvc.filteredProjectPlanningList[i].ffi_id;
                    newObj.st_con_scr = projectsSvc.filteredProjectPlanningList[i].st_con_scr;
                    newObj.stcon_kgyr = projectsSvc.filteredProjectPlanningList[i].stcon_kgyr;
                    if(projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects.length && projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects[0] !== null){
                        for(let m = 0; m < projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects[m].length; m++){
                            newObj.project = projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects[m].project;
                            newObj.priority = projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects[m].priority;
                            newObj.priority = projectsSvc.filteredProjectPlanningList[i].st_data[k].st_projects[m].priority;
                        };
                    }
                }
            }            
            arr.push(newObj);
        }
        console.log('arr test', arr);
    }

    function calcExports(){
        let calcArr = [];
        // console.log('firing');
        for(let i = 0; i < projectsSvc.riverStabilityRes.length; i++){
            // console.log('i', i)
            let newObj = {};
            newObj.ffi_id = projectsSvc.riverStabilityRes[i].ffi_id;
            newObj.low = projectsSvc.riverStabilityRes[i].low;
            newObj.mod = projectsSvc.riverStabilityRes[i].mod;
            newObj.ir = projectsSvc.riverStabilityRes[i].ir;
            newObj.none = projectsSvc.riverStabilityRes[i].none;
            newObj.robust = projectsSvc.riverStabilityRes[i].robust;
            newObj.unconsac = projectsSvc.riverStabilityRes[i].unconsac;
            newObj.veg_ac50 = projectsSvc.riverStabilityRes[i].veg_ac50;
            newObj.stor_conn = projectsSvc.riverStabilityRes[i].stor_conn;
            // newObj.rc_projid = projectsSvc.filteredProjectPlanningList[i].rc_projid;
            // newObj.rc_projectid = projectsSvc.filteredProjectPlanningList[i].rc_projectid;
            // newObj.rc_project_name = projectsSvc.filteredProjectPlanningList[i].rc_project_name;
            // newObj.st_data_huc12 = projectsSvc.filteredProjectPlanningList[i].st_data_huc12;
            // newObj.st_data_st_con_scr = projectsSvc.filteredProjectPlanningList[i].st_data_st_con_scr;
            // newObj.st_data_st_projectid = projectsSvc.filteredProjectPlanningList[i].st_data_st_projectid;
            // newObj.st_data_st_project_name = projectsSvc.filteredProjectPlanningList[i].st_data_st_project_name;
            // for(let k = 0; k < projectsSvc.filteredProjectPlanningList[i].rc_)
            calcArr.push(newObj);
        }
        // console.log('arr test', calcArr);
    }

    function handleClickLogic(tab){
        if (appStateSvc.tableSelected !== tab && appStateSvc.showTable === false) {
            appStateSvc.setTableSelected(tab);
            appStateSvc.setShowTable(true);
            appStateSvc.setCurrentSliderHeight(500)
            appStateSvc.setResetSliderHeight(false);
        }
        else if(appStateSvc.tableSelected !== tab && appStateSvc.showTable === true){
            appStateSvc.setTableSelected(tab);
            // appStateSvc.setCurrentSliderHeight(window.innerHeight - 300);
        }
        else if (appStateSvc.tableSelected == tab && appStateSvc.showTable === false){
            if(appStateSvc.currentSliderHeight == window.innerHeight - 70){
                appStateSvc.setCurrentSliderHeight(500);
            }
            appStateSvc.setShowTable(true);
        }
        //last condition = appStateSvc.tableSelected == tab && appStateSvc.showTable === true
        else {
            appStateSvc.setShowTable(false);
            appStateSvc.setCurrentSliderHeight(window.innerHeight - 70);
        }
    }

    function cancelButton_PJP(){
        projectsSvc.setFilteredProjectPlanningList([]);
        projectsSvc.setRiverStabilityRes([]);
        projectsSvc.setStreamStabilityRes([]);
        // appStateSvc.clearSearch();
        appStateSvc.setShowTable(false);
        appStateSvc.setCurrentSliderHeight(appStateSvc.defaultSliderHeight);
        appStateSvc.setTableSelected("");
        setCheckedStreamFFI_Ids([]);
        setCheckedRiverFFI_Ids([]);
        //this clears everything
        appStateSvc.setCheckedRivers([]);
        appStateSvc.setCheckedStreams([]);
        appStateSvc.setCheckedProjectTypes([]);
        appStateSvc.setRiverRowObj([]);
        appStateSvc.setStreamRowObj([]);
        appStateSvc.setStreamProjects([]);
        appStateSvc.setStorageRowObj([]);
        appStateSvc.setWq_Rivers([]);
        appStateSvc.setWq_Streams([]);
        appStateSvc.setWq_Storage([]);
    };

    function cancelButton_PhosCredit(){
        handleClickLogic('projects');
        // appStateSvc.setCurrentSliderHeight(appStateSvc.defaultSliderHeight);
        setCheckedStreamFFI_Ids([]);
        setCheckedRiverFFI_Ids([]);
        // clears WQ tab
        appStateSvc.setWq_Rivers([]);
        appStateSvc.setWq_Streams([]);
        appStateSvc.setWq_Storage([]);
        appStateSvc.setRiverRowObj([]);
        appStateSvc.setStreamRowObj([]);
        appStateSvc.setStreamProjects([]);
        appStateSvc.setStorageRowObj([]);
        appStateSvc.setCheckedRivers([]);
        appStateSvc.setCheckedStreams([]);
        appStateSvc.setCheckedProjectTypes([]);
        projectsSvc.setRiverStabilityRes([]);
        projectsSvc.setStreamStabilityRes([]);
        criteriaSvc.setCheckedProjects([]);
    };

    // useEffect(() => {
    //     console.log('useEffect hook activate!');
    //     // let target = document.getElementById('phos-credit-calc-tab');
    //     // console.log('getBoundingClientRect test', target.getBoundingClientRect());
    //     if(appStateSvc.currentWindowHeight < appStateSvc.currentSliderHeight){
    //         console.log('height check', appStateSvc.currentWindowHeight, appStateSvc.currentSliderHeight);
    //         alert('caught ya!')
    //     }
    // }, [window.height])

    // useEffect(() => {

    //     appStateSvc.setCurrentWindowHeight(window.innerHeight)
    // });

    function toggleAllRiverCorrSection(){
        let localVal = [];
        let localVal_Stream = [];
        if(expandAll == true){
            for(let i = 0; i < projectsSvc.filteredProjectPlanningList.length; i++){
                // console.log('riverCorrShown', projectsSvc.filteredProjectPlanningList[i]);
                localVal.push(projectsSvc.filteredProjectPlanningList[i].ffi_id);

                if(projectsSvc.filteredProjectPlanningList[i].st_data.length && projectsSvc.filteredProjectPlanningList[i].st_data[0] !== null){
                    for(let j = 0; j < projectsSvc.filteredProjectPlanningList[i].st_data.length; j++){
                        localVal_Stream.push(projectsSvc.filteredProjectPlanningList[i].st_data[j].ffi_id)
                    }
                }
            }
            // console.log('final product', localVal);
            setRiverCorrShown(localVal);
            setStreamSegmentShown(localVal_Stream);
            return setExpandAll(false);
        }else{
            setRiverCorrShown(localVal);
            setStreamSegmentShown(localVal_Stream);
            return setExpandAll(true);
        }
    };
    
    function exportCSV(str){
        appStateSvc.setMakeAlert_Export(true);
        appStateSvc.setReportToExport(str);
    };

    function wq_ExportCSV(){
        if(appStateSvc.wq_Rivers.length && appStateSvc.wq_Streams.length && appStateSvc.wq_Storage.length){
            return exportCSV('wq-tab');
        }else{
            return;
        }
    }

    function contentGeneration(){
        return (
            <div className="table-container">
                <div className="table-content">
                    <div className="table-tabs">
                        <div className="title-tab" id="project-table-tab"
                            style={appStateSvc.tableSelected === "projects" ? {'backgroundColor': 'white'} : {}}
                            onClick={
                                () => {
                                    handleClickLogic('projects');
                                }
                            }
                                // style={appStateSvc.tableSelected == "projects" ? {'backgroundColor': 'white'} : {'backgroundColor': 'silver'}}
                        >
                            <p>Project Screening <span style={appStateSvc.tableSelected == 'projects' ? null : {'display': 'none'}}><FcExpand id={appStateSvc.showTable == true ? 'chevron': 'reverse-chevron'}/></span></p>
                            {/* <p>Project Screening</p> */}
                        </div>
                        <div className={highlight_calcInput ? "highlight title-tab" : "title-tab"} id="phos-credit-calc-tab"
                            style={appStateSvc.tableSelected === 'phos-credit-calc' ? {'backgroundColor': 'white'}: {}}
                            onClick={
                                () => {
                                    handleClickLogic('phos-credit-calc');
                                }
                            }
                        >
                            <p>Calculation Inputs<span style={appStateSvc.tableSelected == 'phos-credit-calc' ? null : {'display': 'none'}}><FcExpand id={appStateSvc.showTable == true ? 'chevron': 'reverse-chevron'}/></span></p>
                        </div>
                        <div className={highlight_wqBenefit ? "highlight title-tab" : "title-tab"} id="wq-benefit-tab"
                            style={appStateSvc.tableSelected == 'water-quality-benefit' ? {'backgroundColor': 'white'} : {}}
                            onClick={
                                () => {
                                    handleClickLogic('water-quality-benefit');
                                }
                            }>
                            <p>Water Quality Benefit</p>
                        </div>
                        <div className={highlight_floodBenefit ? "highlight title-tab" : "title-tab"} id="resiliency-benefit-tab"
                            style={appStateSvc.tableSelected == 'resiliency' ? {'backgroundColor': 'white'} : {}}
                            onClick={
                                () => {
                                    handleClickLogic('resiliency');
                                }
                            }>
                            <p>Floodplain Resiliency Benefit</p>
                        </div>
                        <div className="title-tab">
                            <p>Habitat Benefit</p>
                        </div>
                        <div className="title-tab">
                            <p>Benefit Summary</p>
                        </div>
                        {/* <div class="title-tab">
                            <p>Cost Effectiveness</p>
                        </div> */}
                        <div class="title-tab" className="title-tab" id="cost-effectiveness"
                            style={appStateSvc.tableSelected == 'cost-effectiveness' ? {'backgroundColor': 'white'} : {}}
                            onClick={
                                () => {
                                    handleClickLogic('cost-effectiveness');
                                }
                            }>
                            <p>Cost Effectiveness <span style={appStateSvc.tableSelected == 'cost-effectiveness' ? null : {'display': 'none'}}><FcExpand id={appStateSvc.showTable == true ? 'chevron': 'reverse-chevron'}/></span></p>
                        </div>
                    </div>
                    {appStateSvc.showTable ? 
                        <div className="table-data">
                            {appStateSvc.tableSelected === "projects" ? 
                            <>
                                <section className="clear-button-container">
                                    <button className="export-button" onClick={() => exportCSV('proj-screening')}>Export CSV</button>
                                    {/* <button onClick={() => testScreenshot()}>Click Test</button> */}
                                    <button className="expand-all-button" onClick={() => toggleAllRiverCorrSection()}>{expandAll ? 'Expand' : 'Collapse'} All</button>
                                    <button className="clear-proj-screening-button" onClick={() => cancelButton_PJP()}>Clear Results</button>
                                </section>
                                {/* <p className="table-description">This table provides screening level information about projects you have selected. It can be exported as a csv or you can select to add one or more specific river corridors and/or a single stream segment to a project calculations module to further explore tailored project outcomes. Once you have selected corridor/stream subunits, go to the Calculation Inputs tab.</p> */}
                                <ProjectScreening 
                                    riverCorrShown={riverCorrShown} 
                                    setRiverCorrShown={setRiverCorrShown} 
                                    streamSegmentShown={streamSegmentShown}
                                    setStreamSegmentShown={setStreamSegmentShown}
                                    checkedStreamFFI_Ids={checkedStreamFFI_Ids}
                                    setCheckedStreamFFI_Ids={setCheckedStreamFFI_Ids}
                                    checkedRiverFFI_Ids={checkedRiverFFI_Ids}
                                    setCheckedRiverFFI_Ids={setCheckedRiverFFI_Ids}
                                    setHighlight_calcInput={setHighlight_calcInput}
                                    cancelButton_PJP={cancelButton_PJP}
                                    setHighlight_wqBenefit={setHighlight_wqBenefit}
                                    setHighlight_floodBenefit={setHighlight_floodBenefit}
                                />
                            </>
                            : null }
                            
                            {appStateSvc.tableSelected === "phos-credit-calc" ? 
                            <>
                                {/* <button onClick={() => calcExports()}>Click Test</button> */}
                                <section className="clear-button-container"> 
                                    <button className="export-button" onClick={() => exportCSV('phos-calc')}>Export CSV</button>
                                    <button className="clear-proj-screening-button" onClick={() => cancelButton_PhosCredit()}>Clear Results</button>
                                </section>
                                <div id="phos-credit-calc-table" className="table">
                                    <div id="phos-credit-calc-container" style={{marginBottom: appStateSvc.currentSliderHeight + 20}}>
                                        <PhosCreditCalc 
                                            setHighlight_calcInput={setHighlight_calcInput}
                                            setHighlight_floodBenefit={setHighlight_floodBenefit}
                                            setHighlight_wqBenefit = {setHighlight_wqBenefit}
                                        />
                                    </div>
                                </div>
                            </>
                            : null }

                            {appStateSvc.tableSelected === "water-quality-benefit" ? 
                                <>
                                    <div className="wq-button-container">
                                        <button className={appStateSvc.wq_Rivers.length && appStateSvc.wq_Streams.length && appStateSvc.wq_Storage.length ? "export-button" : "export-button-disabled"} onClick={() => wq_ExportCSV()}>Export CSV</button>
                                    </div>
                                    <WaterQualityBenefit
                                        setCheckedRiverFFI_Ids={setCheckedRiverFFI_Ids}
                                        checkedRiverFFI_Ids={checkedRiverFFI_Ids}
                                        setHighlight_wqBenefit = {setHighlight_wqBenefit}
                                    />
                                </>
                            : null}

                            {appStateSvc.tableSelected === "resiliency" ? 
                                <Resiliency
                                    setHighlight_floodBenefit ={setHighlight_floodBenefit}
                                />
                            : null}

                            {appStateSvc.tableSelected === "cost-effectiveness" ? 
                                <CostEffectiveness/> 
                            : null}

                        </div>
                    : null}
                </div>
            </div>
        )
    }

    return(
        <>
            {contentGeneration()}
        </>
    )
}
